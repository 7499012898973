<template>
  <div class="push-replay-detail">
    <van-row type="flex" justify="center" align="center" class="card">
      <van-col span="24">
        <van-field style="border-radius: 6px 0 0 0;" readonly label="标题" :value="cardInfo.title"></van-field>
        <van-field readonly label="计划出发时间" :value="cardInfo.startTime"></van-field>
        <van-field readonly style="border-radius: 0 0 0 6px;" label="计划返回时间" :value="cardInfo.endTime"></van-field>
      </van-col>
    </van-row>
    <div class="list" v-for="(item,index) in list" v-bind:key="index">
      <van-row type="flex" justify="start" align="center">
        <van-col span="20">
          <van-row class="list-title">
            {{ item.customerName }}
          </van-row>
          <van-row>
            <van-col class="list-info" span="4">{{ item.createdPeople }}</van-col>
            <van-col class="list-info" span="6">{{ item.enterpriseNature }}</van-col>
            <van-col class="list-info" span="6">{{ item.enterpriseType }}</van-col>
            <van-col class="list-info" span="8">{{ item.group }}</van-col>
          </van-row>
          <van-row class="list-info">
            {{ item.addressDetailed }}
          </van-row>
        </van-col>
        <van-col span="4" @click="to(item.to)">
          <van-icon :name='item.status == "未批复" || item.status == "批复中" ? "edit":"eye-o" '/>
        </van-col>
      </van-row>
    </div>
    <div class="leaderOfficial">
      <van-field type="textarea" label="领导评价" v-model="allOfficial" placeholder="领导评价"
                 style="height: 150px;"></van-field>
      <div style="text-align: right">
        <van-button v-if="false" size="large" type="primary" :disabled="flag" @click="saveAllOfficial()">保存</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";

export default {
  name: "BulkCrmsPushReplayEdit",
  components: {CrmTabBar},
  data() {
    return {
      flag: false,
      cardInfo: {
        startTime: '',
        endTime: '',
        title: ''
      },
      list: [],
      allOfficial: '',
      isHidden: false
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    to(to) {
      this.$router.push(to);
    },
    init() {
      this.$ajax.post('/api/ajax/replay/getBulkCrmReplayList.json',
          {
            titleId: this.$route.params.id,
            createdPeople: this.$store.getters.userId,
            replayId: '',
            titleName: '',
          }).then(res => {
        if (res.status == 200) {
          console.log(res.data)
          if (res.data.length > 0) {
            this.list = res.data
            this.cardInfo.title = res.data[0].titleName
            this.cardInfo.startTime = res.data[0].plandateStart
            this.cardInfo.endTime = res.data[0].plandateEnd
            for (let i = 0; i < this.list.length; i++) {
              this.list[i].to = '/BulkCrmsPushReplayDetailEdit/' + this.list[i].replayId
            }
            this.allOfficial = this.list[0].allOfficial
            if (this.$store.getters.userId == '05001' || this.$store.getters.userId == '19060') {
              this.isHidden = true
            }
          }
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('初始化失败！');
      })
    },
    saveAllOfficial() {
      this.flag = true
      this.$ajax.post('/api/ajax/replay/updateBulkCrmReplayAll.json',
          {
            titleId: this.list[0].titleId,
            allOfficial: this.allOfficial,
            createdPeople: this.list[0].createdName
          }).then(res => {
        if (res.status == 200) {
          this.$message.success('保存成功！');
          //重新初始化查询
          setTimeout(() => {
            this.$router.push({path: '/index'})
          }, 200);
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('保存失败！');
      })
    }
  }
}
</script>

<style scoped>
.push-replay-detail {
  background: #f3f3f3;
  height: 100%;
  overflow-y: scroll
}

.push-replay-detail .card {
  margin: 10px 5px;
  background: #378FF2;
  border-radius: 6px;
  box-shadow: 6px 6px 5px #888888;
}

.push-replay-detail .list {
  background: #FFFFFF;
  padding: 5px 10px;
  margin-top: 5px;
}

.push-replay-detail .list-title {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  font-style: normal;
  letter-spacing: 0;
  line-height: 24px;
  text-decoration: none;
}

.push-replay-detail .list-info {
  padding-top: 5px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  color: #827E7E;
  font-style: normal;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: none;
}

.push-replay-detail .leaderOfficial {
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  background-color: #FFFFFF;
}
</style>